import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import {Badge, Col, Row} from "react-bootstrap";

export default function SimpleGallery(props) {


    let printView = props.printView ? props.printView : false;

    useEffect(() => {

        if(!printView){
            let lightbox = new PhotoSwipeLightbox({
                gallery: '#' + props.galleryID,
                children: 'a',
                pswpModule: () => import('photoswipe'),
                bgOpacity: 0.5,
                spacing: 0.1,
                allowPanToNext: true,
                loop: false,
                pinchToClose: true,
                closeOnVerticalDrag: true,
                closeTitle: 'Close the dialog',
                zoomTitle: 'Zoom the photo',
                arrowPrevTitle: 'Go to the previous photo',
                arrowNextTitle: 'Go to the next photo',
                showHideAnimationType: 'zoom',
                arrowPrev: true,
                arrowNext: true,
                zoom: true,
                close: true,
                counter: true
    
            });
            lightbox.init();
    
            return () => {
                lightbox.destroy();
                lightbox = null;
            };
        }

    }, []);
 


    let columnSize = 12;
    if(props.size){
        columnSize = props.size;
    }else{
        if(props.images.length <= 1){
            columnSize = 12;
        }
        if(props.images.length <= 3){
            columnSize = 6;
        }
        if(props.images.length >= 4){
            columnSize = 4;
        }
    }


    if(props.singleImage){
        let image = props.images[0];
        let largeURL = printView ? image.base64 : image.largeURL;
        let thumbnailURL = printView ? "" : image.largeURL;
        return (
            <div style={{width:'100%'}} className="pswp-gallery" id={props.galleryID}>
                <a
                    href={thumbnailURL}
                    data-pswp-width={image.width}
                    data-pswp-height={image.height}
                    key={props.galleryID+"singleimage"}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img alt={image.desc} style={{maxWidth:'100%'}} src={largeURL} />
                </a>
            </div>
        );
    }else{
        return (
            <div style={{width:'100%'}} className="pswp-gallery" id={props.galleryID}>
                <Row>
                    {props.images.map(function(image, index) {
                        let largeURL = printView ? image.base64 : image.largeURL;
                        let thumbnailURL = printView ? "" : image.largeURL;
                        return(
                            <Col xs={12} lg={columnSize}>
                                {printView?
                                <div className={"imageWrapper"}>
                                    <img alt={image.desc} style={{maxWidth:'100%'}} src={largeURL} />
                                    <br />
                                    <Badge bg={"dark"}>{image.extension}</Badge> <span>{image.description}</span>
                                </div>
                                :
                                <div className={"imageWrapper"}>
                                    <a
                                        href={thumbnailURL}
                                        data-pswp-width={image.width}
                                        data-pswp-height={image.height}
                                        key={props.galleryID + '-' + index}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img alt={image.desc} style={{maxWidth:'100%'}} src={largeURL} />
                                    </a><br />
                                    <Badge bg={"dark"}>{image.extension}</Badge> <span>{image.description}</span>
                                </div>
                                }
                        </Col>
                        );
                })}
                </Row>
            </div>
        );
    }
}