import React, {Component} from 'react';


import config from "../../config";
import {Badge, Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleInstructorPage.css'
import {
    faBox, faBoxes, faDiagramProject, faEnvelope,
    faHashtag, faHourglassEnd, faSignOut,
    faUser,
    faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../includes/LoadingScreen";
import InlinePageHeader from "../../includes/InlinePageHeader";
import PageContent from "../../includes/PageContent";
import CourseBadge from "../../components/badges/CourseBadge";
import TopicBadges from "../../components/badges/TopicBadges";
import CourseBadges from "../../components/badges/CourseBadges";
import DateTimeUtil from "../../util/DateTimeUtil";
import CenteredMessage from "../../includes/CenteredMessage";
import PageHeader from "../../includes/PageHeader";
import UserController from "../../controller/UserController";

import he from "he";
import _ from "lodash";
import APIController from "../../controller/APIController";
import TextInputFormField from '../../components/formFields/TextInputFormField';
class SingleInstructorPage extends Component {

    constructor(props) {
        super(props);
        this.loginAsUserIdField = React.createRef();
        this.state = {
            isFetching: true,
            reload:true,
            id:props.params.id,
            instructor : null,
            selectedUserId : null
        };
    }


    async reload(){
        try {
            this.setState({
                isFetching:true
            })

            let queryParams = {
                id: this.state.id,
            };
            let params = new URLSearchParams(queryParams);
            const json = await APIController.getInstructorByParams(params);
            this.setState({
                isFetching: false,
                reload:false,
                instructor : json
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                instructor : null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    render() {
        if(!this.state.isFetching && this.state.instructor == null){
            return (
                <>
                    <PageHeader title={"Ausbilder Profil"} fontAwesomeIcon={faUser}/>
                    <PageContent>
                        <CenteredMessage>
                            <FontAwesomeIcon icon={faUser} />
                            Das Profil konnte nicht geladen werden
                        </CenteredMessage>
                    </PageContent>
                </>
            )
        }

        return (
            <div className={"SingleInstructorPage"}>
                {this.state.isFetching? (<LoadingScreen />):(
                    <>
                        <PageContent>
                            <Row>
                                {UserController.isInstructorAdmin() &&
                                    <Col xs={12} lg={12}>
                                        <div className={'profileBlock'}>
                                            <h5><FontAwesomeIcon icon={faUserAstronaut} /> Login als Ausbilder oder Azubi</h5>
                                            <div className='mb-2'>
                                                <Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Col style={{ maxWidth: "300px", flexShrink: 0 }}>
                                                        <TextInputFormField
                                                            title={""}
                                                            ref={this.loginAsUserIdField}
                                                            rows={1}
                                                            placeholder={"Uniplus-CRM ID"}
                                                            description={""}
                                                            onChange={(value) => {
                                                                let prevState = this.state;
                                                                prevState.selectedUserId = value;
                                                                this.setState({
                                                                    ...prevState
                                                                })
                                                            }}
                                                            value={""}
                                                        />
                                                    </Col>
                                                    <Col style={{ flex: "1 1 auto" ,maxWidth: "300px", }}>
                                                        <Button onClick={async ()=>{
                                                            let user = await UserController.switchLoginUser(this.state.selectedUserId)
                                                            if(user == null){
                                                                window.alert("Login fehlgeschlagen. UserId '"+this.state.selectedUserId+"' korrekt?")
                                                            }else{
                                                                if(user.type === UserController.TYPES.TRAINEE){
                                                                    window.location = "/trainee/"+user.id+"/profile"
                                                                }else{
                                                                    window.location = "/instructor/"+user.id+"/profile"
                                                                }
                                                            }
                                                        }}>Login</Button>
                                                    </Col>
                                                </Row>
                                                <small><b>Hinweis:</b> Dieses Feature ist nur für Power-User (Admin-Ausbilder) aktiviert</small>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faUser} /> Name</h5>
                                        <div>
                                            <span>{he.decode(this.state.instructor.firstname+" "+this.state.instructor.name)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faEnvelope} /> E-Mail</h5>
                                        <div>
                                            <span>{he.decode(this.state.instructor.email)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHourglassEnd} /> Gültig bis</h5>
                                        <div>
                                            {!_.isEmpty(this.state.instructor.gueltigBis) &&
                                                <span>{
                                                    DateTimeUtil.parseIsoDateOfYear(this.state.instructor.gueltigBis) === "01.01.1960"?
                                                        "unbegrenzt":
                                                        DateTimeUtil.parseIsoDateOfYear(this.state.instructor.gueltigBis)}</span>
                                            }
                                            {_.isEmpty(this.state.instructor.gueltigBis) &&
                                                <span>Keine Angabe</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faUser} /> Rolle</h5>
                                        <div>
                                            {UserController.isInstructorAdmin() &&
                                                <span>Ausbilder (Admin)</span>
                                            }
                                            {!UserController.isInstructorAdmin() &&
                                                <span>Ausbilder</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faBoxes} /> Gruppen <small>({this.state.instructor.courses.length})</small></h5>
                                        <div>
                                            <CourseBadges courses={this.state.instructor.courses} limit={50}></CourseBadges>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </PageContent>
                    </>
                )}
            </div>
        );
    }
}


export default SingleInstructorPage;