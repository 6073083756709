import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCancel,
    faComment,
    faCommenting,
    faSave,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import DateTimeUtil from "../../util/DateTimeUtil";

import './ComponentFooter.css';
import {random} from "lodash/number";
import {Alert, Badge, Button, Col, Row} from "react-bootstrap";
import UserController from '../../controller/UserController';

function ActionIconButton({ children, handler }) {
    return (
        <Button
            type="button"
            variant={"light"}
            className={"btn"}
            onClick={() => {handler()}}
        >
            {children}
        </Button>
    );
}

class ComponentFooter extends Component {
    constructor(props) {
        super(props);
        this.hasComments = props.hasComments;
        this.hasEvaluation = props.hasEvaluation;
        this.showCommentInputBox = false;
        this.component = props.component;
        this.updateComponent = props.updateComponent;
        this.onCommentsUpdated = props.onCommentsUpdated;


        this.printMode = props.printMode || false;
        this.instructorMode = props.instructorMode || false;

        this.show = this.show.bind(this);
        this.addComment = this.addComment.bind(this);
        this.cancelAddComment = this.cancelAddComment.bind(this);
        this._handleDataChange = this._handleDataChange.bind(this);

        if(!this.component.config.hasOwnProperty("comments")) {
            this.component.config.comments = [];
        }

        this.state = {
            showCommentInputBox: false,
            commentValue : "",
            renderKey: 1
        };
    }

    show(){
        this.setState(prevState => ({
            ...prevState,
            showCommentInputBox:true
        }));
    }

    addComment(){
        if(!this.component.hasOwnProperty('config') || this.component.config === null){
            this.component.config = {};
        }
        if(!this.component.config.hasOwnProperty('comments') || this.component.config.comments === null){
            this.component.config.comments = [];
        }
        this.component.config.comments.push({
            dateTime: new Date(),
            text:this.state.commentValue
        })
        this.updateComponent(this.component);
        this.onCommentsUpdated(this.state.commentValue);
        this.setState(prevState => ({
            ...prevState,
            showCommentInputBox:false,
            commentValue:""
        }));
    }

    removeComment(index){
        this.component.config.comments.splice(index, 1);
        this.updateComponent(this.component);
        this.onCommentsUpdated(this.state.commentValue);
    }

    cancelAddComment(){
        this.setState(prevState => ({
            ...prevState,
            showCommentInputBox:false,
            commentValue:""
        }));
    }

    _handleDataChange(id,value) {
        this.setState(prevState => ({
            ...prevState,
            commentValue:value.target.value
        }));
    };

    render() {
        return(
            <div className={"ComponentFooter"}>
                <div className={"componentHeaderIcons"}>

                    {this.component.config.comments != null && this.component.config.comments.length > 0 &&
                        <div className={"worksheetComments"}>
                                <Row>
                                    {this.component.config.comments.map( (comment, idx) => {
                                        return (<Row style={{marginTop:'1em',marginBottom:'0.5em'}}>
                                        <Col xs={12} sm={12}>
                                            <Alert key={'primary'} variant={'primary'} style={{marginBottom:0}}>
                                                <Badge className={"commentTimeBox"}  bg={"light"}>
                                                    <div style={{display:"inline-block"}}>
                                                        <span style={{marginRight:'0.2em',whiteSpace:'nowrap',fontWeight:'bold'}} className={"editedDate"}><FontAwesomeIcon style={{marginLeft:'0.2em'}} icon={faComment}/> {DateTimeUtil.getHumanReadableDate(comment.dateTime,false)},</span>
                                                        <span style={{whiteSpace:'nowrap'}} className={"editedTime"}>{DateTimeUtil.getHumanReadableTime(comment.dateTime)}</span>
                                                    </div>
                                                </Badge>
                                                {!this.printMode && UserController.isInstructor() &&
                                                    <Button className={"commentDeleteButton"} size={"sm"} variant={"light"} onClick={() => {
                                                        if (window.confirm("Möchten Sie das Kommentar wirklich löschen?")) {
                                                            this.removeComment(idx);
                                                        }
                                                    }}>
                                                        <span style={{marginRight:'0.2em',whiteSpace:'nowrap',fontWeight:'bold'}} className={"editedDate"}><FontAwesomeIcon style={{marginLeft:'0.2em'}} icon={faTrash}/> Löschen</span>
                                                    </Button>
                                                }
                                                <span className={"commentText"}>{comment.text}</span>
                                            </Alert>

                                        </Col></Row>)
                                    })}
                                </Row>

                        </div>
                    }
                    {this.hasComments && this.state.showCommentInputBox &&
                        <div className={"mt-2 addCommentBox componentIcon"}>
                            <FontAwesomeIcon title={"Kommentar Funktion aktiviert"} icon={faCommenting}/> Kommentar eingeben ...
                            <textarea
                                value={this.state.commentValue}
                                onChange={()=>{}}
                                onChange={(value) => this._handleDataChange('text',value)}
                                className={"form-control"}
                                style={{width:'100%'}} rows={3}>
                             </textarea>
                            <div className={"mt-2"}>
                                <Button style={{marginRight:'0.5em'}} variant={"light"}  onClick={this.cancelAddComment}><FontAwesomeIcon icon={faCancel} /> Abbrechen</Button>
                                <Button variant={"light"}  onClick={this.addComment}><FontAwesomeIcon icon={faSave} /> Speichern</Button>
                            </div>
                        </div>
                    }
                    {this.hasComments && this.props.instructorMode && !this.state.showCommentInputBox && !this.printMode &&
                        <div className={"mt-2"}>
                            <ActionIconButton key={random(0,300)} handler={this.show}>
                                <FontAwesomeIcon title={"Kommentar Funktion aktiviert"} icon={faCommenting}/> Kommentieren</ActionIconButton>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
export default ComponentFooter;