import React, {Component} from 'react';
import TextareaInput from "./input/TextareaInput";
import TableInput from "./input/TableInput";
import MultipleChoiceInput from "./input/MultipleChoiceInput";
import FileUploadInput from "./input/FileUploadInput";
import GefaerdungsbeurteilungInput from "./input/GefaerdungsbeurteilungInput";
import ComponentUtil from "./ComponentUtil";
import HeadlineTextComponent from "./text/HeadlineTextComponent";
import ContentTextComponent from "./text/ContentTextComponent";
import ImageContentTextComponent from "./text/ImageTextComponent";
import DownloadTextComponent from "./text/DownloadTextComponent";
import ExternalLinkTextComponent from "./text/ExternalLinkTextComponent";
import ComponentFooter from "./text/ComponentFooter";
import MarkdownTextComponent from "./input/MarkdownTextComponent";




class ComponentBlock extends Component {
    constructor(props) {
        super(props);

        this.component = props.component;

        /* Set default values if not initialized */
        if(this.component.config.showEditorView === undefined){
            this.component.config.showEditorView = true;
        }
        if(this.component.config.openCard === undefined){
            this.component.config.false = false;
        }
        if(!this.component.config.titleHidden === undefined) {
            this.component.config.titleHidden = false;
        }
        if(this.component.config.openCard === undefined){
            this.component.config.openCard = true;
        }

        this.readOnlyMode = props.readOnlyMode || false;


        this.onLoadingFinished = props.onLoadingFinished || function (){};


        if(this.readOnlyMode){
            this.printMode = true; //view for pdf print
            this.instructorMode = true; //view for instructor, so without input fields but text renders
            this.showEditorView = false; //view to make component settings / configurations
            this.writeModeBasedOnPhase = false; //is trainee able to change values?
        }else{
            this.printMode = props.printMode || false;
            this.instructorMode = props.instructorMode || false;
            this.showEditorView = props.showEditorView || false;
            this.writeModeBasedOnPhase = props.writeModeBasedOnPhase || false;
        }

        this.onCommentsUpdated = props.onCommentsUpdated || function (){};

        this.toggleCard = this.toggleCard.bind(this);
        this.onComponentUpdate = props.onComponentUpdate;
        this.updateComponent = this.updateComponent.bind(this);
    }

    toggleCard(){
        this.component.config.openCard = !this.component.config.openCard;
        this.updateComponent(this.component);
    }


    updateComponent(component){
        this.onComponentUpdate(component);
    }

    renderComponentContent(){

        let uniqueStateKey = "_"+this.component.config.commentFunctionChecked
            +"_"+this.component.config.evaluationChecked;

        if(this.component.type == "textInput"){
            return <div className='textInput'>
                <TextareaInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "markdownText"){
            return <div className='markdownText'>
                <MarkdownTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "tableInput"){
            return <div className='tableInput'>
                <TableInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "multipleChoice"){
            return <div className='multipleChoice'>
                <MultipleChoiceInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "fileUpload"){
            return <div className='fileUpload'>
                <FileUploadInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "gefaehrdungsbeurteilung"){
            return <div className='gefaehrdungsbeurteilung'>
                <GefaerdungsbeurteilungInput
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "headline"){
            return <div className='headline'>
                <HeadlineTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={true}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "text"){
            return <div className='text'>
                <ContentTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "image"){
            return <div className='image'>
                <ImageContentTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={this.writeModeBasedOnPhase}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "download"){
            return <div className='download'>
                <DownloadTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={true}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else if(this.component.type == "externalLink"){
            return <div className='externalLink'>
                <ExternalLinkTextComponent
                    key={uniqueStateKey}
                    updateComponent={this.updateComponent}
                    writeModeBasedOnPhase={true}
                    showEditorView={this.showEditorView}
                    instructorMode={this.instructorMode}
                    printMode={this.printMode}
                    onLoadingFinished={this.onLoadingFinished}
                    component={this.component} />
            </div>
        }else{
            return (<div></div>);
        }
    }

    renderComponentAccording(){
        return(
            <div style={{marginBottom:'1em'}}>
                {!this.component.config.titleHidden &&
                <h4 className={"componentHead"}>{this.props.component.type !== 'headline' && ComponentUtil.getIconByComponentType(this.props.component.type)} {this.props.component.title}</h4>
                }
                {/*
                <ComponentHeader
                    title={this.props.component.title}
                    description={this.props.component.config.description}
                    hasComments={this.props.component.config.commentFunctionChecked}
                    hasEvaluation={this.props.component.config.evaluationChecked}
                />*/}
                <div className={"componentBody"}>
                    {this.renderComponentContent()}
                </div>
                <ComponentFooter
                    hasComments={true}
                    hasEvaluation={true}
                    instructorMode={this.instructorMode && !this.readOnlyMode}
                    component={this.props.component}
                    printMode={this.printMode}
                    onCommentsUpdated={this.onCommentsUpdated}
                    updateComponent={(component) => this.updateComponent(component)}
                />
            </div>

        )
    }


    renderInfo(){
        return <>
            <span>writeModeBasedOnPhase: </span>{this.writeModeBasedOnPhase?"yes":"no"}
            <span>, showEditorView: </span>{this.showEditorView?"yes":"no"}
            <span>, instructorMode: </span>{this.instructorMode?"yes":"no"}
            <span>, printMode: </span>{this.printMode?"yes":"no"}<br />
        </>
    }

    render() {
        return(
            <div>
                {/*this.renderInfo()*/}
                {this.renderComponentAccording()}</div>
        )
    }
}
export default ComponentBlock;