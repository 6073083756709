const config = {
    dev : {
        APP_NAME: "Digitales Arbeitsblatt Dev",
        API_HOST_URL: "http://localhost:8080",
        WORKER_URL: "https://staging-worker.bauabc.de",
        MSAL_CLIENT_ID: "17be20df-a52f-4827-b461-2bbee68e9d46", // from dema
        MSAL_TENANT_ID: "9a7f47cb-bdb1-45de-8bc0-7e90a7886767", // from dema
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/9a7f47cb-bdb1-45de-8bc0-7e90a7886767', // Replace the placeholder with your tenant subdomain
            redirectUri: 'http://localhost:3000/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
        API_MESSSAGE_SHOW_TIMER: 60000, //milliseconds
        TOAST_MESSSAGE_SHOW_TIMER: 5000, //milliseconds
        STEP_INTERVAL_TIMER: 10000, //milliseconds
        REGEX_FOR_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        VALIDATION_MESSAGE_ON_PASSWORD_REGEX: "Muss mindestens 6 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl",
    } ,
    staging : {
        APP_NAME: "Digitales Arbeitsblatt Staging",
        API_HOST_URL: "https://staging-api-my.ausbildung.cloud",
        WORKER_URL: "https://staging-worker.bauabc.de",
        MSAL_CLIENT_ID: "0200f95e-3ba9-41b5-af4b-ebd8947685c0", // from bauabc
        MSAL_TENANT_ID: "e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0", // from bauabc
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0',
            redirectUri: 'https://staging-my.ausbildung.cloud/redirect',
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
        API_MESSSAGE_SHOW_TIMER: 60000, //milliseconds
        TOAST_MESSSAGE_SHOW_TIMER: 5000, //milliseconds
        STEP_INTERVAL_TIMER: 10000, //milliseconds
        REGEX_FOR_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        VALIDATION_MESSAGE_ON_PASSWORD_REGEX: "Muss mindestens 6 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl",
    } ,
    production : {
        APP_NAME: "Digitales Arbeitsblatt",
        API_HOST_URL: "https://api-my.ausbildung.cloud",
        WORKER_URL: "https://worker.bauabc.de",
        MSAL_CLIENT_ID: "0200f95e-3ba9-41b5-af4b-ebd8947685c0", // from bauabc
        MSAL_TENANT_ID: "e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0", // from bauabc
        msalAuthConfig: {
            authority: 'https://login.microsoftonline.com/e48cfed5-edc8-4ab5-a9f7-9cb31f72a6b0',
            redirectUri: 'https://my.ausbildung.cloud/redirect',
        },
        PRADLE_PDF_EXPORT_TOKEN : "KJHjzgrfdjzTURC7645dczfzcZasdg",
        API_MESSSAGE_SHOW_TIMER: 60000, //milliseconds
        TOAST_MESSSAGE_SHOW_TIMER: 5000, //milliseconds
        STEP_INTERVAL_TIMER: 10000, //milliseconds
        REGEX_FOR_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        VALIDATION_MESSAGE_ON_PASSWORD_REGEX: "Muss mindestens 6 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl",
    }
};
console.log("#############################################################################");
console.log({ production: 'p', staging: 's', development: 'd' }[process.env.REACT_APP_ENV]);
console.log("#############################################################################");
export default config[process.env.REACT_APP_ENV || 'production'];