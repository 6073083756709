import React, { Component } from 'react';
import {Alert, Col, Row} from "react-bootstrap";

import '../components/WorksheetPrintViewComponent.css';
import ComponentBlock from "./ComponentBlock";
import APIController from "../controller/APIController";
import ProjectTaskDescriptionPrintView from "./ProjectTaskDescriptionPrintView";
import logo from "../assets/bauabc_logo.PNG";
import FileUtil from "../util/FileUtil";


class WorksheetPrintViewComponent extends Component {

    constructor(props) {
        super(props);
        this.worksheetId = props.worksheetId;
        this.counter = 0;
        this.state = {
            isFetching: true,
            worksheet: null,
            message: null,
            error: false,
            loadingCounter: 0
        }
    }

    /*
    componentDidCatch(error, errorInfo) {
        // Fehlerinformationen für Logging oder Reporting erfassen
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        this.setState(prevState => ({
            ...prevState,
            error: errorInfo,
            loadingCounter: 0
        }));
    }*/


    async reload(){
        try {
            this.setState(prevState => ({
                ...prevState,
                isFetching:true,
                loadingCounter: 0
            }));

            const worksheetId = encodeURIComponent(this.worksheetId);
            const worksheet = await APIController.getPublicWorksheetById(worksheetId);
            worksheet.files = await FileUtil.loadMediaFilesMeta(worksheet.files, true);

            let componentsCount = 0;
            worksheet.sections.forEach((section) => {
                if(!section.hide){
                    section.components.forEach((component) => {
                        if(!component.hide){
                            componentsCount++;
                        }
                    });
                }
            });

            this.setState(prevState => ({
                ...prevState,
                isFetching: false,
                worksheet: worksheet,
                loadingCounter: 0,
                componentsCount: componentsCount
            }));
          

        } catch (error) {
            console.log(error);
            /*this.setState(prevState => ({
                ...prevState,
                isFetching: false,
                message: error,
                loadingCounter: 0
            }));*/
        }
    }

    componentDidMount() {
        this.reload();
    }

    /*
    componentDidUpdate(prevProps) {
        if (this.state.isFetching != null && !this.state.isFetching) {
            //Starte Waiting trigger for waiting for images loaded event
            this.waitForElementInViewport(".diwo-print-body").then((element) => {
                this.observeAndWaitForImages().then(() => {
                    document.getElementById("diwo-print-body")?.classList.add("diwo-print-body-fully-loaded");
                    console.log("images fully loaded");
                    window.alert("images fully loaded");
                });
            });
        }
    }*/

    renderTestComponents(component, index){
        return(
            <>
                <h5>Instructor-View</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={true}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
                <h5>Printview</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={true}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
                <h5>InputView (Write enabled)</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={true}
                />
                <h5>InputView (Write disabled)</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
            </>
        )
    }


    nope = function (component) {
        this.counter = this.counter + 1;
        let val = this.state.componentsCount - this.counter;
        console.log(component.type+" - "+component.id+" loaded ("+this.counter+"/"+this.state.componentsCount+")");
        if(val == 0){
            console.log("worksheet fully loaded - ready for print")
            this.setState(prevState => ({
                ...prevState,
                fullyLoaded:true
            }));
        }
    }.bind(this)


    renderComponents(component, index){
        if(this.state != null && !this.state.isFetching){
            //console.log(this.state)
            return(
                <>
                    <ComponentBlock
                        key={component.id}
                        onRemoveComponentClicked={() => {}}
                        component={component}
                        onComponentUpdate={() => {}}
                        componentIndex={index}
                        onCommentsUpdated={() => {}}
                        onLoadingFinished={this.nope}
                        readOnlyMode={false}
                        printMode={true}
                        instructorMode={false}
                        showEditorView={false}
                        writeModeBasedOnPhase={false}
                    />
                </>
            )
        }else{
            return (
                <span>lade: {this.worksheetId}</span>
            );
        }
    }

    renderWoskheetPrint(){
        return (
            <>
                    <div id='diwo-print-body' className={this.state.fullyLoaded?"diwo-print-body diwo-print-body-fully-loaded":"diwo-print-body"}>
                    <div className="page">
                        <div style={{padding:'0.5em 0em'}}>
                            <h5 className="text-uppercase"><img src={logo} style={{height: "30px",marginRight:'0.5em',marginLeft:'0.5em'}} className="App-logo" alt="logo"/> <b>Digitales Arbeitsblatt</b></h5>
                        </div>
                        <div className="content">
                            <div className="worksheetView WorksheetPrintViewComponent">
                                <div>
                                    <ProjectTaskDescriptionPrintView
                                        worksheet={this.state.worksheet}
                                        trainee={this.state.worksheet.trainee}
                                    />
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <div className={"my-worksheet-print"}>
                                            <div className={"sections-print"}>
                                                {this.state.worksheet.files.length > 0 &&
                                                    <div className={"section-print-wrapper"}>
                                                        <div className={"section-print-header"}>
                                                            <Row>
                                                                <h2>Projektzeichnungen / Grafiken</h2>
                                                            </Row>
                                                        </div>
                                                        <div className={"section-print"} key={"sec_0"}>
                                                            <div className={"components-print"}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        {FileUtil.renderTitleImageFiles(this.state.worksheet.files, true)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.worksheet.sections.map((section, sectionIndex) => {
                                                    return (!section.hide) &&
                                                        <div className={"section-print-wrapper"}>
                                                            <div className={"section-print-header"}>
                                                                <Row>
                                                                    <h2>{section.title}</h2>
                                                                </Row>
                                                            </div>
                                                            <div className={"section-print"} key={sectionIndex}>
                                                                <div className={"components-print"}>
                                                                    <Row>
                                                                        {section.components.map((component, index) => {
                                                                            return (
                                                                                <Col key={index}
                                                                                     xs={component.config.size ? component.config.size : 12}>
                                                                                    {!component.hide &&
                                                                                        this.renderComponents(component, index)
                                                                                        //this.renderTestComponents(component, index)
                                                                                    }
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ;
                                                })}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="footer">
                        </div>
                        <div className="disclaimer">
                            <span>my.ausbildung.cloud</span>
                        </div>
                    </div>
                    </div>
                </>
        )
    }

    render() {

        console.log("Render...");
        if(this.state.error){
            return (
                <div className="worksheetView WorksheetPrintViewComponent">
                    <h2>Arbeitsblatt konnte nicht geladen werden</h2>
                    <Alert variant={"danger"}>
                        {JSON.stringify(this.state.error)}
                    </Alert>
                </div>
            );
        }

        if(this.state == null || this.state.isFetching){
            return (
                <span>lade: {this.worksheetId}</span>
            );
        }else if(this.state.worksheet === null){
            return (
                <div className="worksheetView WorksheetPrintViewComponent">
                    <span>{JSON.stringify(this.state.message)}</span>
                </div>
            );
        }else{
            console.log("Start render: WorksheetPrintViewComponent");
            return (
                <>
                {this.renderWoskheetPrint()}
                </>
            )
        }
            
    }
}


export default WorksheetPrintViewComponent;