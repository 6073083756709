import React, {Component, createRef,} from "react";
import './WorksheetImageDropzone.css'
import {Button, Col, Row} from "react-bootstrap";
import TextInputFormField from "./formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft, faArrowRight,
    faCircle,
    faClose,
    faDownload,
    faPlusSquare, faStar, faToggleOff, faToggleOn,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import config from "../config";
import LoadingIcon from "../includes/LoadingIcon";
import APIController from "../controller/APIController";


    class WorksheetImageDropzone extends Component {

        constructor(props) {
            super(props);
            this.dropzoneRef = createRef();
            this.onDrop = this.onDrop.bind(this);
            this.onFilesUpdated = props.onFilesUpdated;
            this.projectId = props.projectId !== undefined ? props.projectId : null;
            this.autoSave = props.autoSave !== undefined ? props.autoSave : false;

            let files = this.props.files !== undefined ? this.props.files : [];
            //loop over all files and make preset values
            files.map( async (file, idx) => {
              if(!files[idx].hasOwnProperty('isPrimary')){
                  files[idx].isPrimary = false;
              }
            });
            if(files.length == 1){
                files[0].isPrimary = true;
            }

            this.state = {
                files: files,
                showEditMode: false,
                showLoading: false
            }
        }

        openDialog(){
            // Note that the ref is set async,
            // so it might be null at some point
            if (this.dropzoneRef.current) {
                this.dropzoneRef.current.open()
            }
        };

        _updateFileName(fileId,newFileDescription){
            this.state.files.map( async (file, idx) => {
                if (file.id == fileId) {
                    this.state.files[idx].description = newFileDescription;
                    if (this.autoSave && this.projectId != null) {
                        await APIController.updateProjectFiles(this.projectId, this.state.files);
                    }
                }
            });
        }

        async _removeFileById(fileId){
            this.setState({
                showLoading:true
            })
            this.state.files.map( async (file, idx) => {
                if (file.id === fileId) {
                    await fetch(`${config.API_HOST_URL}/files/${file.name}`, {method: "DELETE",});
                    this.state.files.splice(idx, 1);
                    if(this.autoSave && this.projectId != null){
                        await APIController.updateProjectFiles(this.projectId, this.state.files);
                    }
                    this.state.showLoading = false;
                    this.onFilesUpdated(this.state.files);
                    this.setState(this.state);
                }
            });
        }
        async _setStarFile(fileId){
            this.state.files.map( async (file, idx) => {
                if (file.id == fileId) {
                    file.isPrimary = true;
                }else{
                    file.isPrimary = false;
                }
            });
            if (this.autoSave && this.projectId != null) {
                await APIController.updateProjectFiles(this.projectId, this.state.files);
            }
            this.onFilesUpdated(this.state.files);
            this.setState(this.state);
        }

        async swap(fileId,left = true){
            let index = 0;
            this.state.files.map( async (file, idx) => {
                if (file.id === fileId) {
                    index = idx
                }
            });

            if(left){
                if(index > 0){
                    let temp = this.state.files[index-1];
                    this.state.files[index-1] = this.state.files[index];
                    this.state.files[index] = temp;
                }

            }else{
                if(index < this.state.files.length-1){
                    let temp = this.state.files[index+1];
                    this.state.files[index+1] = this.state.files[index];
                    this.state.files[index] = temp;
                }
            }

            if (this.autoSave && this.projectId != null) {
                await APIController.updateProjectFiles(this.projectId, this.state.files);
            }
            this.onFilesUpdated(this.state.files);
            this.setState(this.state);
        }

        async _toggleFile(fileId){
            this.state.files.map( async (file, idx) => {
                if (file.id === fileId) {
                    if(file.deactivated){
                        file.deactivated = false;
                    }else{
                        file.deactivated = true;
                    }
                }
            });
            if (this.autoSave && this.projectId != null) {
                await APIController.updateProjectFiles(this.projectId, this.state.files);
            }
            this.onFilesUpdated(this.state.files);
            this.setState(this.state);
        }

        async onDrop(acceptedFiles){
            this.state.showLoading = true;
            this.setState(this.state);
            let formData = new FormData();
            for (const file of acceptedFiles) {
                if(file.size >= 100 * 1024 * 1024){
                    alert("Datei ist zu groß!");
                    this.state.showLoading = false;
                    this.setState(this.state);
                    return null;
                }
                formData.append('file',file);
                let response = await fetch(config.API_HOST_URL+"/files/upload",{
                        method: "POST",
                        body: formData
                    }
                );
                let jsonResponse = await response.json();
                if(jsonResponse.url !== ""){
                    jsonResponse.isPrimary = false;
                    this.state.files.push(
                        jsonResponse
                    )
                    if(this.state.files.length == 1){
                        this.state.files[0].isPrimary = true;
                    }
                    this.setState(this.state);
                }
                else{
                    window.alert(jsonResponse.message);
                    return null;
                }


            }

            if (this.autoSave && this.projectId != null) {
                await APIController.updateProjectFiles(this.projectId, this.state.files);
            }
            this.state.showLoading = false;
            this.setState(this.state);
        }


        render(){
            if(this.state.showLoading){
                return <div><LoadingIcon style={{height:'10em',marginTop:'1em'}} /></div>;
            }

            return (
            <div className={"WorksheetImageDropzone"}>
                <Row>
                    {this.state.showEditMode &&
                        this._renderEditMode()
                    }
                    <Col xs={12} sm={12} lg={12} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                        {this._renderViewMode()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={3} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                        {this._renderChangeVisibilityButton()}
                    </Col>
                    <Col xs={12} lg={9} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                        {this.props.children}
                    </Col>
                </Row>
                </div>
            )
        }

        _renderChangeVisibilityButton(){
            return <Button style={{marginTop:'1em'}} className={"btn-sm"} variant={"outline-dark"} title={"Titel verbergen"}
                           onClick={() => {
                               this.state.showEditMode = !this.state.showEditMode;
                               this.setState(this.state);
                           }}>
                {
                    this.state.showEditMode ?
                        <span><FontAwesomeIcon icon={faClose}/> Schließen</span>:
                        <span><FontAwesomeIcon icon={faPlusSquare}/> Hinzufügen / Anpassen</span>
                }
            </Button>;
        }

        _renderViewMode(){


            if(!this.state.showEditMode){
                return (
                    <div className={"project-file-preview-simple"} style={{width:'100%'}}>
                        <Row>
                        {this.state.files.map((file) => {
                        return(
                            <Col xs={6} md={4} lg={2}>
                                <div className={file.deactivated?"imageFileWrapper deactivated":"imageFileWrapper"}>
                                    <img style={{width:'100%',background:'white'}} src={file.url}  alt={"fileimage"}/>
                                </div>
                            </Col>)
                        })}
                        </Row>
                    </div>
                );
            }else{
                return <div style={{width:'100%'}}>
                    <Row>
                        {this.state.files.map((file) => {
                            return(
                                <Col xs={12} md={6} lg={3}>
                                    <div className={file.deactivated?"imageFileWrapper deactivated":"imageFileWrapper"}>
                                        <div style={{display:'inline-block', marginRight:'0.2em'}} className={"activatedButton"}>
                                            {file.deactivated?
                                                <Button size={"sm"} variant={"danger"} onClick={() => {
                                                    this._toggleFile(file.id);
                                                    this.onFilesUpdated(this.state.files);
                                                }}><span><FontAwesomeIcon icon={faToggleOff} /></span>
                                                </Button>
                                                :
                                                <Button size={"sm"} variant={"success"}  onClick={() => {
                                                    this._toggleFile(file.id);
                                                    this.onFilesUpdated(this.state.files);
                                                }}><span><FontAwesomeIcon icon={faToggleOn} /></span>
                                                </Button>
                                            }
                                        </div>
                                        <>
                                            {!file.deactivated &&
                                                <>
                                                    <div style={{display:'inline-block', marginRight:'1em'}}  className={"starrButton"}>
                                                        <Button size={"sm"} variant={file.isPrimary?"primary":"light"} onClick={() => {
                                                            this._setStarFile(file.id);
                                                            this.onFilesUpdated(this.state.files);
                                                        }}>{file.isPrimary?<span><FontAwesomeIcon icon={faStar} /></span>:<FontAwesomeIcon icon={faCircle} />}
                                                        </Button>
                                                    </div>
                                                </>
                                            }

                                            {!file.deactivated &&
                                                <div style={{float:"right"}}>
                                                    <div style={{display:'inline-block',marginRight:'0.2em'}}>
                                                        <Button size={"sm"} variant={"light"}  onClick={() => {
                                                            window.open(file.url);
                                                        }}>
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </Button>
                                                    </div>
                                                    <div style={{display:'inline-block', marginRight:'0.2em'}}>
                                                        <Button size={"sm"} variant={"light"}  onClick={async () => {
                                                            if (window.confirm("Grafik: '" + file.description + "' wirklich löschen?")) {
                                                                await this._removeFileById(file.id);
                                                            }
                                                        }}><FontAwesomeIcon icon={faTrashCan} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                            <div className={"project-file-preview"}>
                                                <div style={{width:'100%'}}>
                                                    <div style={{position:"relative",width:'100%'}}>
                                                        <img src={file.url} style={{background:'white'}}  alt={"fileimage"}/>
                                                        <div style={{float:"left"}}>
                                                            <div style={{display:'inline-block',marginRight:'0.2em'}}>
                                                                <Button size={"sm"} variant={"light"}  onClick={async () => {
                                                                    await this.swap(file.id, true);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faArrowLeft} />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div style={{float:"right"}}>
                                                            <div style={{display:'inline-block',marginRight:'0.2em'}}>
                                                                <Button size={"sm"} variant={"light"}  onClick={async () => {
                                                                    await this.swap(file.id, false);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginBottom:'1em'}}>
                                                <TextInputFormField
                                                    key={file.deactivated}
                                                    disabled={file.deactivated}
                                                    title={""}
                                                    rows={1}
                                                    placeholder={"Titel/Beschreibung"}
                                                    description={""}
                                                    onChange={(value) => {
                                                        this._updateFileName(file.id,value);
                                                        this.onFilesUpdated(this.state.files);
                                                    }}
                                                    value={file.description}
                                                />
                                            </div>
                                            <div style={{clear:'both'}}></div>

                                            </>
                                    </div>
                                </Col>)
                        })}
                    </Row>
                    </div>
            }



        }

        _renderEditMode(){
            return (
                this.state.showLoading?
                    <div><LoadingIcon style={{height:'10em',marginTop:'1em'}} /></div>
                    :
                <div className={"dropzone-container"}>
                    <Dropzone onDrop={this.onDrop} ref={this.dropzoneRef} noClick noKeyboard>
                        {({getRootProps, getInputProps, acceptedFiles}) => {
                            return (
                                <div>
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>Drag & Drop Dateien hier</p>
                                            <button
                                                type="button"
                                                onClick={() => this.openDialog()}
                                            >
                                                Datei auswählen
                                            </button>
                                        </div>
                                    <br />
                                </div>
                            );
                        }}
                    </Dropzone>

                </div>
            );
        }

}

export default WorksheetImageDropzone;