import config from "../config";
import _ from "lodash";
import {addSessionTokenToRequestHeader} from "../services/fetchInterceptor.service";
import UserController from "./UserController";
import objectUtil from "../util/objectUtil";
import FileUtil from "../util/FileUtil";

class APIController{

    static WORKSHEET_STATES = {
        "DRAFT_WORKING_SHEET" : "DRAFT_WORKING_SHEET",
        "OPEN_WORKING_SHEET" : "OPEN_WORKING_SHEET",
        "SUBMITTED_WORKING_SHEET" : "SUBMITTED_WORKING_SHEET",
        "GRADED_WORKING_SHEET" : "GRADED_WORKING_SHEET",
        "DISCARDED_WORKING_SHEET" : "DISCARDED_WORKING_SHEET",
    }

    static async addWorkingsheetTemplate(topicId, templateId = null){
        addSessionTokenToRequestHeader();
        let params = {};
        params.projectId = topicId;
        if(templateId != null){
            params.templateId = templateId;
        }
        let urlParams = new URLSearchParams(params);

        let url = config.API_HOST_URL + "/projects?"+urlParams
        let response = await fetch(url, {
            method: 'PUT'
        });
        let project = await response.json();
        return project;
    }

    static async updateWorkingsheetTemplate(project){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/projects", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(project) // body data type must match "Content-Type" header
        });
        if(!response.ok){
            throw Error(response.statusText);
        }
        return await response.json();
    }

    static async deleteWorkingsheetTemplate(projectId){
        let urlParams = new URLSearchParams({projectId:projectId});
        let url = config.API_HOST_URL + "/projects?"+urlParams
        let response = await fetch(url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!response.ok){
            throw Error(response.statusText);
        }
        return await response.json();
    }




    static async getWorksheetById(worksheetId){
        let timestamp = new Date().getTime(); //Cache-Busting to fix strange cache behavior
        const params = new URLSearchParams({
            worksheetId: worksheetId,
            timestamp: timestamp
        });
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/worksheets?" + params.toString());
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async updateProjectFiles(projectId, projectFilesArray){
        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        postHeaders.append("Authorization", 'Bearer '+UserController.getUser().sessionToken);
        const params = new URLSearchParams({
            projectId: projectId
        });
        addSessionTokenToRequestHeader();
        const raw = JSON.stringify(projectFilesArray);
        let resp = await fetch(`${config.API_HOST_URL}/projects/files?${params.toString()}`, {
            method: "POST",
            headers: postHeaders,
            body: raw
        });
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }

    }


    static async getAllProjectTemplatesByProfessionId(professionId){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/projects/templates");
        if (!response.ok) {
            throw Error(response.statusText);
        }
        let projects = await response.json();
        if(projects.length > 0){
            return projects.filter(p=>p.professionId === professionId)
        }else{
            return [];
        }
    }

    static async getProjectTemplates(){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/projects/templates");
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return await response.json();
    }

    static async getPDFFile(worksheetId){
        addSessionTokenToRequestHeader();
        let resp =  await fetch(config.API_HOST_URL + "/worksheets/printpdf?worksheetId="+worksheetId);
        console.log(resp)
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        let blobResp = await resp.blob();
        let url = URL.createObjectURL(blobResp);
        return url;
    }

    static async downloadPDFFile(worksheetId, traineeId, courseId, topicId){
        addSessionTokenToRequestHeader();
        let resp =  await fetch(config.API_HOST_URL + "/worksheets/printpdf?worksheetId="+worksheetId);
        console.log(resp)
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        let blobResp = await resp.blob();
        let url = URL.createObjectURL(blobResp);

        const trainee = await APIController.getTraineeById(traineeId);
        let fileName = trainee.name+"_"+trainee.groupName+"_"+courseId+"_"+topicId+".pdf";

        // Temporären Link erstellen und simulierten Download starten
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.download = fileName; // Name der heruntergeladenen Datei
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink); // Bereinigen
        URL.revokeObjectURL(url); // URL freigeben

        return url;
    }


    static async getUserProfile(params){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/users/me?" + params.toString());
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();

    }

    static async verifyTAN(TAN){
        let resp = await fetch( `${config.API_HOST_URL}/users/verify/tan/${TAN}`);
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async requestVerificationCode(email, tan=null){

        let url = `${config.API_HOST_URL}/users/requestCode/${encodeURIComponent(email)}${tan !== null ? "?tan="+encodeURIComponent(tan) : ""}`;
        let resp = await fetch(url);
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async requestForgotPasswordEmail(email){
        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "email": email
        });
        let resp = await fetch(`${config.API_HOST_URL}/users/forgotPassword/requestCode`, {
            method: "POST",
            headers: postHeaders,
            body: raw
        });
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async resetPassword(password, nuance){
        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "password": password,
            "nuance": nuance
        });
        let resp = await fetch(`${config.API_HOST_URL}/users/forgotPassword/setPassword`, {
            method: "POST",
            headers: postHeaders,
            body: raw
        });
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async updatePassword(oldPassword, newPassword){
        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        postHeaders.append("Authorization", 'Bearer '+UserController.getUser().sessionToken);
        addSessionTokenToRequestHeader();
        let resp = await fetch(`${config.API_HOST_URL}/users/updatePassword`, {
           method: 'POST',
            headers: postHeaders,
           body: JSON.stringify({
               "oldPassword": oldPassword,
               "newPassword": newPassword
           })
        });

        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async verifyVerificationCode(email, raw){

        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        raw = JSON.stringify(raw);
        let resp = await fetch(`${config.API_HOST_URL}/users/verifyCode/${email}`, {
            method: "POST",
            headers: postHeaders,
            body: raw
        });

        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async resetInitialEmailAndInitialPassword(email, tan, password){

        const postHeaders = new Headers();
        postHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "email": email,
            "tan": tan,
            "password": password
        });

        let resp = await fetch(`${config.API_HOST_URL}/users/initialSetUpAccount`, {
            method: "POST",
            headers: postHeaders,
            body: raw
        });

        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async getTraineeProfile(showWarning = false){
        addSessionTokenToRequestHeader();
        const user = UserController.getUser();
        if(!UserController.isTrainee()){
            return null;
        }
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        let resp = await fetch(config.API_HOST_URL + "/trainees/profile", requestOptions);
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        localStorage.removeItem("showProfileWarning");
        let isFinished = await objectUtil.isTraineeProfileFinished(jsonRes);
        if(showWarning && !isFinished){
            localStorage.setItem("showProfileWarning",'true');
        }
        return jsonRes;
    }

    static async updateTraineeProfile(profileData){
        //addSessionTokenToRequestHeader();
        const user = UserController.getUser();
        if(!UserController.isTrainee()){
            return null;
        }
        const requestOptions = {
            method: "POST",
            redirect: "follow",
            body: JSON.stringify(profileData),
            headers: {
                "Content-Type": "application/json",
            }
        };
        let resp = await fetch(config.API_HOST_URL + "/trainees/profile", requestOptions);
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async getPublicWorksheetById(worksheetId){
        let timestamp = new Date().getTime(); //Cache-Busting to fix strange cache behavior
        const params = new URLSearchParams({
            worksheetId: worksheetId,
            timestamp: timestamp
        });
        let resp = await fetch(config.API_HOST_URL + "/worksheets/public?" + params.toString());
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getTraineeById(traineeId){
        let timestamp = new Date().getTime(); //Cache-Busting to fix strange cache behavior
        const params = new URLSearchParams({
            id: traineeId,
            timestamp: timestamp
        });
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/trainees?" + params.toString());
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getWorksheetByParam(param, value, extra= {}){
        let timestamp = new Date().getTime(); //Cache-Busting to fix strange cache behavior
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set(param, value);
        urlParam.set('timestamp', timestamp);

        if(!_.isEmpty(extra)){
            /*for(letkey in parametersToAdd){
                params.append(key, parametersToAdd[key]);
            }*/
        }
        let urlParamString = urlParam.toString();
        let resp = await fetch(config.API_HOST_URL + "/worksheets?" + urlParamString);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getAllTopicsGroupedByProfessionId(professionId, showDetails = false, patternFilter = []){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('professionId',professionId);
        urlParam.set('showDetails',(showDetails?"1":"0"));
        let urlParamString = urlParam.toString();
        if(patternFilter.length > 0){
            urlParamString += "&topicIdRegex="+patternFilter.join("&topicIdRegex=")
        }
        let resp = await fetch(config.API_HOST_URL + "/topics/getAllTopicsGroupedByProfessionId?" + urlParamString);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getTopicBlacklist(){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/topics/blacklist");
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }


    static async getAllProfessionNamesFromTopics(){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/topics/getAllProfessionNames");
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }


    /**
     * Uploads a file, but first make some checks
     * @param file file-object how it is coming from dropzone onDrop
     * @param maxAllowedSize max default size 10MB
     * @param allowedExtensions default ['.pdf','.jpg',...]
     * @returns {Promise<null>}
     */
    static async uploadFile(file, maxAllowedSize = (10 * 1024 * 1024), allowedExtensions = FileUtil.getExtensionWhitelist()){
        let fileExtension = FileUtil.getFileExtension(file.path);
        if(!allowedExtensions.includes(fileExtension)){
            window.alert("Dateityp nicht auf der Whitelist!");
            return null;
        }else if( FileUtil.isDangerousFile(file.path)){
            window.alert("Dateityp nicht erlaubt!");
            return null;
        }else if(file.size >= maxAllowedSize){
            window.alert("Datei ist zu groß!");
            return null;
        }else{
            let formData = new FormData();
            formData.append('file',file)
             let response = await fetch(
                    config.API_HOST_URL+"/files/upload",
                    {
                        method: "POST",
                        body: formData
                    }
                );
            return await response.json();
        }
    }

    static async getAllTopics(showDetails=false,professionId=null){
        addSessionTokenToRequestHeader();

        const urlParam = new URLSearchParams();
        if(professionId != null){
            urlParam.set('professionId',professionId);
        }
        urlParam.set('showDetails',(showDetails?"1":"0"));

        let url = config.API_HOST_URL + "/topics/getAllTopicsGroupedByProfessionId?"+urlParam;
        let resp = await fetch(url);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async migrateAllWorksheets(){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/worksheets/migrate",
            {
                method: 'GET'
            });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async deleteAllWorksheetsByCourseId(courseId){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('courseId',courseId);
        let resp = await fetch(config.API_HOST_URL + "/worksheets/course?"+urlParam.toString(),
            {
                method: 'DELETE'
            });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getEvaluationByWorksheetId(worksheetId = []){
        addSessionTokenToRequestHeader();
        let urlQueryParms = "";
        if (!Array.isArray(worksheetId)) {
            worksheetId = [worksheetId];
        }
        if(worksheetId.length > 0){
            urlQueryParms = "?worksheetId="+worksheetId.join("&worksheetId=")
        }
        let url = config.API_HOST_URL + "/worksheets/evaluate"+urlQueryParms;
        let resp = await fetch(url);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async getEvaluationsByCourseAndTopic(courseId, topic){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('courseId',courseId);
        urlParam.set('topic',topic);
        let url = config.API_HOST_URL + "/worksheets/evaluate?"+urlParam;
        let resp = await fetch(url);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    /**
     *
     * @param courseId
     * @param topic
     * @param evaluations { "traineeId" => evaluation, "traineeId2" => evaluation2, }
     * @returns {Promise<any>}
     */
    static async updateEvaluationsByCourseAndTopic(courseId, topic, evaluations){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('courseId',courseId);
        urlParam.set('topic',topic);
        let resp = await fetch(config.API_HOST_URL + "/worksheets/evaluate?"+urlParam, {
            method: "POST",
            body: JSON.stringify(evaluations),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }


    static async getEvaluationsByWorksheet(worksheet){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('courseId',worksheet.courseId);
        urlParam.set('topic',worksheet.topic);
        let url = config.API_HOST_URL + "/worksheets/evaluate?"+urlParam;
        let resp = await fetch(url);
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        let evaluations = await resp.json();
        return evaluations[worksheet.traineeId];


    }

    static async updateWorksheetEvaluation(worksheetId,evaluation,onFinish,onError){
        addSessionTokenToRequestHeader();
        let postBody = {
            worksheetId : worksheetId,
            evaluation : evaluation
        }

        fetch(config.API_HOST_URL + "/worksheets/evaluate", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postBody) // body data type must match "Content-Type" header
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }


    static async sendPDFBlobToDocuware(pdfBlob,fileName,worksheetId){
        addSessionTokenToRequestHeader();
        const urlParam = new URLSearchParams();
        urlParam.set('worksheetId',worksheetId);
        urlParam.set('fileName',fileName);
        const formData = new FormData();
        formData.append("file", pdfBlob);
        return await fetch(config.API_HOST_URL + "/worksheets/archive/docuware?" + urlParam.toString(), {
            method: 'POST',
            body: formData // body data type must match "Content-Type" header
        });
    }


    static async getCourseById( courseId, extra= {}){
        let timestamp = new Date().getTime(); //Cache-Busting to fix strange cache behavior
        let queryParams = {
            id: courseId,
            timestamp: timestamp
        };
        let params = new URLSearchParams(queryParams);
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/courses?"+params.toString());
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return await response.json();
    }

    static async getCoursesList(params){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/courses?"+params.toString());
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return await response.json();
    }

    static async syncCourseWithParams(params){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/courses/sync?" + params.toString());
        if (!resp.ok) {
            throw Error(resp.statusText);
        }
        console.info("Sync request received");
        return {status: true};
    }

    static async assignWorkingsheet( courseId, traineeId, topicId, extra= {}){
        let queryParams = {
            courseId: courseId,
            traineeId: traineeId,
            topicId: topicId
        };
        let params = new URLSearchParams(queryParams);
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/worksheets/instructor/assign?"+params.toString());
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return await response.json();
    }


    /**
     *
     * @param apiPath
     * @param queryParams object (key value pairs)
     * @returns {Promise<any>}
     */
    static async fetchApiWithParams(apiPath,queryParams = {}){
        addSessionTokenToRequestHeader();
        const params = new URLSearchParams(queryParams);
        let resp = await fetch(config.API_HOST_URL + apiPath+"?" + params.toString());
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }


    static async updateWorksheetV2(worksheet){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/worksheets", {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(worksheet) // body data type must match "Content-Type" header
        });
        return await response.json();
    }

    static async updateWorksheetState(worksheet){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/worksheets/state", {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(worksheet) // body data type must match "Content-Type" header
        });
        return await response.json();
    }

    static async _updateWorksheet(worksheet,worksheetState = null,onFinish,onError){
        addSessionTokenToRequestHeader();
        let params = "";
        if(worksheetState != null){
            params = new URLSearchParams({
                updateState: worksheetState
            });
        }
        fetch(config.API_HOST_URL + "/worksheets?"+ params.toString(), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(worksheet) // body data type must match "Content-Type" header
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }

    static async asssignNewWorksheet(courseId, topicId, instructorId, traineeId, onFinish,onError){
        addSessionTokenToRequestHeader();
        let params = new URLSearchParams({
            courseId: courseId,
            topicId: topicId,
            instructorId: instructorId,
            traineeId: traineeId
        });
        fetch(config.API_HOST_URL + "/worksheets?"+ params.toString(), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(function(response) {
                if (!response.ok) {
                    throw Error("Arbeitsblatt konnte nicht zugewiesne werden. Gibt es für die Projektnummer '"+topicId+"' auch ein Arbeitsblatt-Template?");
                }
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }

    static async deleteWorksheet(worksheetId,forceDelete =false, onFinish,onError){
        addSessionTokenToRequestHeader();
        let params = new URLSearchParams({
            worksheetId: worksheetId,
            forceDelete: forceDelete
        });
        fetch(config.API_HOST_URL + "/worksheets?"+ params.toString(), {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }


    static async reopenWorkingSheet(worksheet,onFinish,onError){
        return APIController._updateWorksheet(worksheet,APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET,onFinish,onError)
    }


    static async finishWorksheet(worksheet,onFinish,onError){
        return APIController._updateWorksheet(worksheet,APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET,onFinish,onError)
    }


    static async updateWorksheet(worksheet,onFinish,onError){
        return APIController._updateWorksheet(worksheet,null,onFinish,onError)
    }

    static async updateWorksheetComments(worksheet){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/worksheets/comments", {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(worksheet) // body data type must match "Content-Type" header
        });
        return await response.json();
    }

    static async updateWorksheetAndPhase(worksheet){
        addSessionTokenToRequestHeader();
        let params = new URLSearchParams({
            updatePhase: true
        });
        let response = await fetch(config.API_HOST_URL + "/worksheets?"+ params.toString(), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(worksheet) // body data type must match "Content-Type" header
        });
        return await response.json();
    }


    static async evaluateWorksheet(worksheet,onFinish,onError){
        // updateWorksheetEvaluation
        //return APIController._updateWorksheet(worksheet,worksheet.state,onFinish,onError)
    }

    /**
     *
     * @param topicFilterPatternArray like ['*-17-1']
     * @param onFinish
     * @param onError
     * @returns {Promise<void>}
     */
    static async getAllProjectsByTopicFilterPattern(topicFilterPatternArray = [],onFinish,onError){
        addSessionTokenToRequestHeader();
        let urlQueryParms = "";
        if(topicFilterPatternArray.length > 0){
            urlQueryParms = "?topicIdRegex="+topicFilterPatternArray.join("&topicIdRegex=")
        }
        fetch(config.API_HOST_URL + "/projects"+urlQueryParms, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }

    static async getAllProjectsByProfessionId(professionId){
        addSessionTokenToRequestHeader();
        let params = new URLSearchParams({
            professionId: professionId
        });
        let result =  await fetch(config.API_HOST_URL + "/projects?"+params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        });
        return await result.json();
    }



    static async sendWorksheetTemplateBulkResetDelete(topicIds = []){
        addSessionTokenToRequestHeader();
        let topicIdsParams = "";
        if(topicIds.length>0){
            topicIdsParams = "?topicIds="+topicIds.join("&topicIds=")
        }
        let url = config.API_HOST_URL + "/projects/bulksetup"+topicIdsParams;
        let result =  await fetch(url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
        });
        return await result.json();
    }
    static async sendWorksheetTemplateBulkReset(professionId,templateId, deleteOldFileteredByTopics=false,
                                                addImageComponentInFirstSection=false,topicIds = []){
        addSessionTokenToRequestHeader();
        let params = new URLSearchParams({
            professionId: professionId,
            templateId: templateId,
            deleteOldFileteredByTopics: deleteOldFileteredByTopics,
            addImageComponentInFirstSection: addImageComponentInFirstSection
        });
        let topicIdsParams = "";
        if(topicIds.length>0){
            topicIdsParams = "&topicIds="+topicIds.join("&topicIds=")
        }
        let url = config.API_HOST_URL + "/projects/bulksetup?"+params.toString()+topicIdsParams;
        let result =  await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        });
        return await result.json();
    }

    static async getInstructorByParams(params){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/instructors?"+params.toString());
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return await response.json();
    }

    static async getProjectsByParams(params = new URLSearchParams()){
        addSessionTokenToRequestHeader();
        let response = await fetch(config.API_HOST_URL + "/projects?"+params.toString());
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return await response.json();
    }

    static async getAllSubjectsByTopicId(topicId,onFinish,onError){
        addSessionTokenToRequestHeader();
        fetch(config.API_HOST_URL + "/topics/"+topicId+"/subjects", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                onFinish(json);
            })
            .catch(function(err) {
                onError(err)
            });
    }

    static async getSyncLogs(){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/sync/readInfo", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async requestSync(){
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/sync", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async cloneTemplates(projectId){
        addSessionTokenToRequestHeader();
        let url = `${config.API_HOST_URL}/projects/templates/clone/${projectId}`;
        let resp = await fetch(url, {
            method : 'POST',
            body: ''
        })
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async getTopicsListAdmin(params){
        // X404 API Endpoint
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/topics?"+params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }
    static async getCoursesListAdmin(params){
        // X404 API Endpoint
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/courses?"+params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }
    static async getInstructorsListAdmin(params){
        // X404 API Endpoint
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/instructors?"+params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }
    static async getTraineesListAdmin(params){
        // X404 API Endpoint
        addSessionTokenToRequestHeader();
        let resp = await fetch(config.API_HOST_URL + "/admin/trainees?"+params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(!resp.ok){
            throw Error(resp.statusText);
        }
        return await resp.json();
    }

    static async createTemplate(parentProjectId = null, professionId = null){
        addSessionTokenToRequestHeader();
        let params = {};
        params.isTemplate = true;
        if(parentProjectId != null){
            params.templateId = parentProjectId;
        }
        if(professionId != null){
            params.professionId = professionId;
        }
        let urlParams = new URLSearchParams(params);
        let url = `${config.API_HOST_URL}/projects?${urlParams}`;
        let resp = await fetch(url, {
            method : 'PUT',
            body: ''
        })
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async sendInviteSupervisor(email){
        addSessionTokenToRequestHeader();
        let url = `${config.API_HOST_URL}/trainees/profile/invite`;
        let resp = await fetch(url, {
            method : 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: email})
        })
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async deleteInvite(email){
        addSessionTokenToRequestHeader();
        let url = `${config.API_HOST_URL}/trainees/profile/invite`;
        let resp = await fetch(url, {
            method : 'DELETE',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: email})
        })
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

    static async removeSupervisor(email){
        addSessionTokenToRequestHeader();
        let url = `${config.API_HOST_URL}/trainees/profile/connection`;
        let resp = await fetch(url, {
            method : 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: email})
        })
        let jsonRes = await resp.json();
        if(!resp.ok){
            throw Error(jsonRes.message);
        }
        return jsonRes;
    }

}
export default APIController;