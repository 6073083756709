import React, {Component} from 'react';


import {Badge, Button, Col, Container, Row, Table, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleTraineePage.css'
import {
    faBoxes,
    faDiagramProject,
    faEnvelope,
    faExclamationTriangle,
    faHashtag,
    faHourglassEnd,
    faSheetPlastic,
    faTools,
    faUser,
    faUserTie,
    faTrashCanArrowUp, faHourglassStart

} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../includes/PageHeader";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import DateTimeUtil from "../../util/DateTimeUtil";
import CourseBadges from "../../components/badges/CourseBadges";
import CenteredMessage from "../../includes/CenteredMessage";
import he from "he";
import APIController from "../../controller/APIController";
import PradleAccordion from "../../components/PradleAccordion";
import _ from "lodash";
import TopicKachel from "../../components/TopicKachel";
import PagePopup from "../../components/PagePopup";
import {Formik} from "formik";
import * as yup from "yup";
class SingleInstructorPage extends Component {

    constructor(props) {
        super(props);
        this.schema = yup.object().shape({
            email: yup.string().email("muss eine gültige E-Mail-Adresse sein").required("Email kann nicht leer sein"),
        });
        this.initialValues = {email:""}
        this.state = {
            isFetching: true,
            reload:true,
            id:props.params.id,
            trainee : null,
            profile : null,
            connectedSupervisors : null,
            pendingInvites: null,
            apiResponseMessage: "",
            alertType: "alert-danger", // or alert-success
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            }
        };
    }



    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            const trainee = await APIController.getTraineeById(this.state.id);
            console.log(trainee);
            trainee.connectedTopics = _.sortBy(trainee.connectedTopics, 'id').reverse();
            trainee.courses = _.sortBy(trainee.courses, 'start').reverse();

            let profileResponse = null;
            let connectedSupervisors = null;
            let pendingInvites = null;
            try{
                profileResponse = await APIController.getTraineeProfile();
                connectedSupervisors = profileResponse.connectedSupervisors;
                pendingInvites = profileResponse.pendingInvites;
            }catch{
                //nope
            }


            this.setState({
                isFetching: false,
                reload:false,
                trainee : trainee,
                profile : profileResponse,
                connectedSupervisors : connectedSupervisors,
                pendingInvites : pendingInvites,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                isFetching: false,
                trainee : null,
                profile: null,
                connectedSupervisors : null,
                pendingInvites: null,
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    getWorksheetsBagde(worksheets){
        if(worksheets.length == 1){
            return (
                <Badge bg={"dark"} style={{fontSize:'0.5em', marginLeft:'1em'}}><FontAwesomeIcon icon={faSheetPlastic} /> 1</Badge>
            )
        }else{
            return (
                <Badge bg={"dark"} style={{fontSize:'0.5em', marginLeft:'1em'}}><FontAwesomeIcon icon={faSheetPlastic} /> {worksheets.length}</Badge>
            )
        }
    }


    async handleRemoveSupervisor(supervisorEmailId){
        if (window.confirm("Sind Sie sicher, dass Sie diesen Verwalter entfernen möchten?")) {
            try {
                this.setState({isFetching: true});
                const response = await APIController.removeSupervisor(supervisorEmailId);
                if(!response.success) {
                    alert(response.message);
                    return;
                }
                alert(response.message);
            } catch (error) {
                alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
            } finally {
                this.setState({isFetching: false});
                await this.reload();
            }
        }
    }
    
    async handleRemoveInvite(email){
        if(window.confirm("Sind Sie sicher, dass Sie die Einladung für den Administrator entfernen möchten?")){
            try {
                this.setState({isFetching: true});
                const response = await APIController.deleteInvite(email);
                if(!response.success) {
                    alert(response.message);
                    return;
                }
                alert(response.message);
            } catch (error) {
                alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
            } finally {
                this.setState({isFetching: false});
                await this.reload();
            }
        }
    }

    handleInviteSupervisor = (e) => {
        e.preventDefault();
        var self = this;
        self.setState({
                popupProps: {
                    title: <h3><FontAwesomeIcon icon={faUserTie} /> Laden Sie Ihren Verwalter ein</h3>,
                    body: <div className={"supervisorInviteForm"}>
                        <Formik initialValues={this.initialValues} validationSchema={this.schema} onSubmit={
                            async (values, { resetForm }) => {
                                try{
                                    self.setState({isLoading:true});
                                    const response = await APIController.sendInviteSupervisor(values.email);
                                    if(!response.success) {
                                        self.setState({
                                            apiResponseMessage: response.message,
                                            alertType: "alert-danger",
                                            isLoading: false
                                        });
                                    }
                                    else {
                                        resetForm();
                                        self.setState({apiResponseMessage: response.message, alertType:"alert-success"});
                                    }
                                } catch (e) {
                                    self.setState({apiResponseMessage: e.message, alertType:"alert-danger"});
                                } finally {
                                    setTimeout(() => {
                                        self.setState({apiResponseMessage: "", alertType:"alert-danger", isLoading:false, popupProps:{show:false}});
                                        self.reload();
                                    }, 5000);


                                }
                            }
                        }>
                            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <div className={"mt-2 mb-3"} style={{lineHeight: "1.6", fontSize: "1rem"}}>
                                            Bitte geben Sie die E-Mail-Adresse des Verwalters ein, den Sie einladen möchten.
                                            Eine Einladung wird an diese E-Mail-Adresse gesendet.
                                            Nachdem der Inhaber der E-Mail-Adresse die Einladung angenommen hat,
                                            kann er Ihre Erfolge einsehen.
                                        </div>
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="E-Mail Addresse"
                                            id="email"
                                            value={values.email}
                                            onBlur={handleBlur}
                                            isInvalid={touched.email && !!errors.email}
                                        onChange={(e) => setFieldValue("email", e.target.value)}/>
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    {this.state.apiResponseMessage &&
                                        <div className={"mt-3 mb-3 alert " + this.state.alertType } dangerouslySetInnerHTML={{__html: this.state.apiResponseMessage}}/>
                                    }
                                    <div className={"d-flex justify-content-end"}>
                                        {!this.state.isLoading ?
                                            <Button variant="primary" type="submit">
                                                <FontAwesomeIcon icon={faUserTie}/> Einladen
                                            </Button> : <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                    ,
                    show: true,
                    showFooter: false
                }
            }
        );
    };


    render() {

        if(!this.state.isFetching && this.state.trainee == null){
            return (
                    <>
                        <PageHeader title={"Auzubildenden Profil"} fontAwesomeIcon={faUser}/>
                        <PageContent>
                            <CenteredMessage>
                                <FontAwesomeIcon icon={faUser} />
                                Das Profil konnte nicht geladen werden
                            </CenteredMessage>
                        </PageContent>
                    </>
                )
        }

        return (
            <div>
                {this.state.isFetching? (<LoadingScreen />):(
                    <div className={"SingleTraineePage"}>
                        <PageContent>
                            <div>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faUser} /> Name</h5>
                                        <div>
                                            <span>{he.decode(this.state.trainee.firstname+" "+this.state.trainee.name)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Teinehmer-Nr.</h5>
                                        <div>
                                            {this.state.trainee.externalId}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Uniplus CRM-ID</h5>
                                        <div>
                                            {this.state.trainee._id}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faEnvelope} /> E-Mail</h5>
                                        <div>
                                            {this.state.trainee.email != ""?
                                                <span>{he.decode(this.state.trainee.email)}</span>
                                                :
                                                <span>Keine E-Mail Adresse</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faTools} /> Beruf</h5>
                                        <div>
                                            {this.state.trainee.professionId != ""?
                                                <span><FontAwesomeIcon icon={faTools} /> {this.state.trainee.professionId} | {he.decode(this.state.trainee.professionName)}</span>
                                                :
                                                <span style={{color:'red'}}><FontAwesomeIcon icon={faExclamationTriangle} /> Beruf vorhanden</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHourglassEnd} /> Gültig bis</h5>
                                        <div>
                                            {!_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>{
                                                    DateTimeUtil.parseIsoDateOfYear(this.state.trainee.gueltigBis) === "01.01.1960"?
                                                        "unbegrenzt":
                                                    DateTimeUtil.parseIsoDateOfYear(this.state.trainee.gueltigBis)}</span>
                                            }
                                            {_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>Keine Angabe</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faBoxes} /> Gruppen <small>({this.state.trainee.courses.length})</small></h5>
                                        <div>
                                            <CourseBadges courses={this.state.trainee.courses} limit={50}></CourseBadges>
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                                {this.state.trainee.courses.map((course,idx)=> {


                                    let worksheets = this.state.trainee.worksheets.filter((ws) => ws.courseId === course._id);

                                    if(worksheets.length == 0){
                                        return <></>
                                    }

                                    return(

                                    <PradleAccordion title={<div>
                                        <FontAwesomeIcon icon={faDiagramProject} /> {course.name}
                                        {this.getWorksheetsBagde(worksheets)}</div>} keyId={"topics_"+course._id} open={(idx===0)}>
                                        <h3>{DateTimeUtil.parseIsoDateOfYear(course.start)} - {DateTimeUtil.parseIsoDateOfYear(course.end)}</h3>
                                        <Row>
                                            {worksheets.map((ws,idx)=>{
                                                if(ws.state === APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET){
                                                    /* Hide worksheets, which are discardedQ */
                                                    //TODO: Put this filter operation in API, instead of frontend
                                                    return <></>;
                                                }
                                                return (
                                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                                        <TopicKachel
                                                            isAzubiKachel={true}
                                                            topicId={ws.topic}
                                                            topicName={(ws.taskName!=="" && ws.taskName!==null)?ws.taskName:"Projekt "+ws.topic}
                                                            courseId={ws.courseId}
                                                            worksheets={[ws]}
                                                        ></TopicKachel>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </PradleAccordion>
                                    );
                                })}
                            </div>
                            <div className={"mt-5 d-flex justify-content-end"}>
                                {/*<Button variant="primary" onClick={(e) => this.handleInviteSupervisor(e)}>
                                    <FontAwesomeIcon icon={faUserTie}/> Verwalter Einladen
                                </Button>*/}
                            </div>

                            {/* Supervisor Management Table */}
                            {this.state.pendingInvites && this.state.pendingInvites.length > 0 &&
                                <div className={"mt-5"}>
                                    <h3>Einladungen</h3>

                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>E-Mail</th>
                                            <th>Eingeladen am</th>
                                            <th>Gültig bis</th>
                                            <th>Status</th>
                                            <th>Aktion</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.pendingInvites.map((invite, index) => (
                                                <tr key={index}>
                                                    <td>{invite.email}</td>
                                                    <td>{DateTimeUtil.parseIsoDateOfYear(invite.createdOn)}</td>
                                                    <td>{DateTimeUtil.parseIsoDateOfYear(invite.expiresAt)}</td>
                                                    <td>{invite.status}</td>
                                                    <td>
                                                        {invite.status === "pending" ?
                                                        <Button size={"sm"} variant="danger"
                                                                onClick={async () => await this.handleRemoveInvite(invite.email)}>
                                                            <FontAwesomeIcon icon={faTrashCanArrowUp}/> Entfernen
                                                        </Button>
                                                        : "--"
                                                        }
                                                    </td>
                                                </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                            }

                            {this.state.connectedSupervisors && this.state.connectedSupervisors.length > 0 &&
                                <div className={"mt-5"}>
                                    <h3>Verwalter</h3>

                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>E-Mail</th>
                                            <th>Mitglied seit</th>
                                            <th>Aktion</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.connectedSupervisors.map((supervisor, index) => (
                                            <tr key={index}>
                                                <td>{supervisor.firstName+ ' ' + supervisor.lastName }</td>
                                                <td>{supervisor.email}</td>
                                                <td>{DateTimeUtil.parseIsoDateOfYear(supervisor.createdOn)}</td>
                                                <td>
                                                    <Button size={"sm"} variant="danger"
                                                            onClick={async () => await this.handleRemoveSupervisor(supervisor.email)}>
                                                        <FontAwesomeIcon icon={faTrashCanArrowUp}/> Entfernen
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>

                            }

                            {/* Supervisor Invite Modal */}
                            <PagePopup
                                className={"supervisorInvitationPopup"}
                                onClose={()=>this.setState({popupProps:{show:false}})}
                                key={this.state.popupProps.show}
                                {...this.state.popupProps}>
                            </PagePopup>

                            {/*
                            {(this.state.profile && this.state.profile.communicationData) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Mobile</h5>
                                                <div>
                                                    {this.state.profile.communicationData.mobile && (this.state.profile.communicationData.mobile)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhoneAlt}/> Phone</h5>
                                                <div>
                                                    {this.state.profile.communicationData.phone && (this.state.profile.communicationData.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.bankDetails) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faBank}/> Kreditinstitut</h5>
                                                <div>
                                                    {(this.state.profile.bankDetails.creditInstitution) && he.decode(this.state.profile.bankDetails.creditInstitution)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> IBAN</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.iban && (this.state.profile.bankDetails.iban)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> BIC</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.bic && (this.state.profile.bankDetails.bic)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faUserTie}/> Abweichender Kontoinhaber</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.differentAccountHolder && he.decode(this.state.profile.bankDetails.differentAccountHolder)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.legalGuardian) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} md={3} lg={3}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faHandshake}/> Anrede</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.salutation && (this.state.profile.legalGuardian.allSalutationOptions.filter((option) => option.key === this.state.profile.legalGuardian.salutation)[0].value)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={9} lg={9}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPerson}/> Name</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.firstname && this.state.profile.legalGuardian.name) && he.decode(this.state.profile.legalGuardian.firstname + " " + this.state.profile.legalGuardian.name)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Telefon</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faEnvelope}/> E-Mail</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.email && (this.state.profile.legalGuardian.email)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faRoad}/> Strasse</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.street && (this.state.profile.legalGuardian.street)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faLocationDot}/> PLZ</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.zip && this.state.profile.legalGuardian.zip}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={8} lg={8}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faTreeCity}/> Ort</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.city && he.decode(this.state.profile.legalGuardian.city)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            }
                            */}
                        </PageContent>
                    </div>
                )}
            </div>
        );
    }
}


export default SingleInstructorPage;